angular.module('app.controllers').controller 'ProductsCtrl', [
  '$scope', '$rootScope', 'APIExhibitors', '$cookies', 'Table', 'APIArticles','CategoryListService'
].append (self, root, APIExhibitors, $cookies, Table, APIArticles,CategoryListService) ->
  
  self.exhibitors = APIExhibitors.query {full: true}

  self.CategoryListService = CategoryListService

  dummy =
    type: 'articles'
    search: ""
    e_cats: ""
    platform365_cat_id_main: ""
    platform365_cat_id_sub: ""
    e_id: ""
    page: 1
    visited: 0
    limit: 12
    shortcode: 'art-' + root.user?.shortcode || 'test'
    auth: root.user?.auth_ticket

  artFilterName = 'articles.filter.' + (root.user?.shortcode || 'test')

  self.params   = $cookies.getObject(artFilterName) || dummy

  self.aParams  = angular.copy(self.params)


  self.articles = Table
    name: 'haptica.articles'
    type: 'partial'
    resource: APIArticles
    params: self.aParams

  self.applyFilter = () ->
    $cookies.putObject(artFilterName, self.params)
    self.aParams = angular.copy(self.params)

    self.articles.params = self.aParams

  self.resetFilter = () ->
    self.params =
      type: 'articles'
      search: ""
      e_cats: ""
      platform365_cat_id_main: ""
      platform365_cat_id_sub: ""
      e_id: ""
      page: 1
      visited: 0
      limit: 12
      shortcode: 'art-' + root.user?.shortcode || 'test'
      auth: root.user?.auth_ticket

    self.applyFilter()
    self.$applyAsync()
    self.subCategories = CategoryListService.getSubCategories()


  # new variables to hold the categories in search filters
  self.mainCategories = CategoryListService.getMainCategories();
  self.subCategories = CategoryListService.getSubCategories(parseInt(self.params.platform365_cat_id_main));

  self.onMainCatChange = () ->
    self.subCategories = CategoryListService.getSubCategories(parseInt(self.params.platform365_cat_id_main))

  self.doSearch = () ->
    self.resetFilter()
    self.articles.params.search = self.searchSuggesions.params.search

  self.clearSearch = () ->
    self.searchSuggesions.params.search = ""
    self.articles.params.search = ""
    self.searchSuggesionsCats = []
    self.searchSuggesionsExhibitors = []
    self.resetFilter()

  self.newSearchParams =
    type: 'articles'
    search: ""
    e_cats: ""
    platform365_cat_id_main: ""
    platform365_cat_id_sub: ""
    e_id: ""
    page: 1
    visited: 0
    limit: 12
    shortcode: 'art-' + root.user?.shortcode || 'test'
    auth: root.user?.auth_ticket

  self.searchSuggesionsCats = []
  self.searchSuggesionsExhibitors = []

  self.onSearchTextChange = () ->
    self.searchSuggesionsCats = []
    self.searchSuggesionsExhibitors = []
    if( self.searchSuggesions.params.search.length >= 2)
      self.searchSuggesionsCats = self.CategoryListService.searchCategoriesByName(self.searchSuggesions.params.search).slice(0,4)
      self.searchSuggesionsExhibitors = self.exhibitors.filter(
        ((exhibitor) ->
           exhibitor.user.company.toLowerCase().startsWith(self.searchSuggesions.params.search.toLowerCase())
        )
      )

  self.searchSuggesions =Table
    name: 'haptica.articles'
    type: 'partial'
    resource: APIArticles
    params: self.newSearchParams

  self.setSuggestedCategory = (category) ->
    self.resetFilter()
    self.searchSuggesions.params.search = category.name
    mainCatId = category.id - category.id % 100
    self.params.platform365_cat_id_main = mainCatId.toString()
    self.onMainCatChange()

    if(category.id % 100 > 0)
      self.params.platform365_cat_id_sub = category.id.toString()

    self.applyFilter()




