angular.module('app.controllers').controller('ProductsCtrl', ['$scope', '$rootScope', 'APIExhibitors', '$cookies', 'Table', 'APIArticles', 'CategoryListService'].append(function(self, root, APIExhibitors, $cookies, Table, APIArticles, CategoryListService) {
  var artFilterName, dummy, ref, ref1, ref2, ref3, ref4;
  self.exhibitors = APIExhibitors.query({
    full: true
  });
  self.CategoryListService = CategoryListService;
  dummy = {
    type: 'articles',
    search: "",
    e_cats: "",
    platform365_cat_id_main: "",
    platform365_cat_id_sub: "",
    e_id: "",
    page: 1,
    visited: 0,
    limit: 12,
    shortcode: 'art-' + ((ref = root.user) != null ? ref.shortcode : void 0) || 'test',
    auth: (ref1 = root.user) != null ? ref1.auth_ticket : void 0
  };
  artFilterName = 'articles.filter.' + (((ref2 = root.user) != null ? ref2.shortcode : void 0) || 'test');
  self.params = $cookies.getObject(artFilterName) || dummy;
  self.aParams = angular.copy(self.params);
  self.articles = Table({
    name: 'haptica.articles',
    type: 'partial',
    resource: APIArticles,
    params: self.aParams
  });
  self.applyFilter = function() {
    $cookies.putObject(artFilterName, self.params);
    self.aParams = angular.copy(self.params);
    return self.articles.params = self.aParams;
  };
  self.resetFilter = function() {
    var ref3, ref4;
    self.params = {
      type: 'articles',
      search: "",
      e_cats: "",
      platform365_cat_id_main: "",
      platform365_cat_id_sub: "",
      e_id: "",
      page: 1,
      visited: 0,
      limit: 12,
      shortcode: 'art-' + ((ref3 = root.user) != null ? ref3.shortcode : void 0) || 'test',
      auth: (ref4 = root.user) != null ? ref4.auth_ticket : void 0
    };
    self.applyFilter();
    self.$applyAsync();
    return self.subCategories = CategoryListService.getSubCategories();
  };
  // new variables to hold the categories in search filters
  self.mainCategories = CategoryListService.getMainCategories();
  self.subCategories = CategoryListService.getSubCategories(parseInt(self.params.platform365_cat_id_main));
  self.onMainCatChange = function() {
    return self.subCategories = CategoryListService.getSubCategories(parseInt(self.params.platform365_cat_id_main));
  };
  self.doSearch = function() {
    self.resetFilter();
    return self.articles.params.search = self.searchSuggesions.params.search;
  };
  self.clearSearch = function() {
    self.searchSuggesions.params.search = "";
    self.articles.params.search = "";
    self.searchSuggesionsCats = [];
    self.searchSuggesionsExhibitors = [];
    return self.resetFilter();
  };
  self.newSearchParams = {
    type: 'articles',
    search: "",
    e_cats: "",
    platform365_cat_id_main: "",
    platform365_cat_id_sub: "",
    e_id: "",
    page: 1,
    visited: 0,
    limit: 12,
    shortcode: 'art-' + ((ref3 = root.user) != null ? ref3.shortcode : void 0) || 'test',
    auth: (ref4 = root.user) != null ? ref4.auth_ticket : void 0
  };
  self.searchSuggesionsCats = [];
  self.searchSuggesionsExhibitors = [];
  self.onSearchTextChange = function() {
    self.searchSuggesionsCats = [];
    self.searchSuggesionsExhibitors = [];
    if (self.searchSuggesions.params.search.length >= 2) {
      self.searchSuggesionsCats = self.CategoryListService.searchCategoriesByName(self.searchSuggesions.params.search).slice(0, 4);
      return self.searchSuggesionsExhibitors = self.exhibitors.filter((function(exhibitor) {
        return exhibitor.user.company.toLowerCase().startsWith(self.searchSuggesions.params.search.toLowerCase());
      }));
    }
  };
  self.searchSuggesions = Table({
    name: 'haptica.articles',
    type: 'partial',
    resource: APIArticles,
    params: self.newSearchParams
  });
  return self.setSuggestedCategory = function(category) {
    var mainCatId;
    self.resetFilter();
    self.searchSuggesions.params.search = category.name;
    mainCatId = category.id - category.id % 100;
    self.params.platform365_cat_id_main = mainCatId.toString();
    self.onMainCatChange();
    if (category.id % 100 > 0) {
      self.params.platform365_cat_id_sub = category.id.toString();
    }
    return self.applyFilter();
  };
}));
